import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"


const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query VacanciesQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 1000
        ) {
          edges {
            node {
              frontmatter {
                slug
                title
              }
            }
          }
        }
    }
  `)

  return (
    <Layout>
      <SEO title="Open Positions" description='View open positions at Edyn Care'/>
      <h1>Open Positions</h1>
      <div className='job-listing-card__list'>
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <div className='job-listing-card__container'>
          <a className='job-listing-card__body' href={node.frontmatter.slug}>
            <h2>{node.frontmatter.title}</h2>
            <div className='job-listing-card__footer'>
              <span>Read more</span>
              <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.41418 1.34326L7.07104 7.00012L1.41418 12.657" stroke="#0F2A2B" stroke-width="2"/>
                </svg>
            </div>
          </a>
        </div>
      ))}      
      </div>
    </Layout>
  )
}

export default IndexPage
